<template>
  <div class="folders">
    <h4>Vers le dossier :</h4>
    <div class="folder-form">
      <div class="folder-menu">
        <div
          v-for="folder in folderTree"
          :key="folder.value"
          :style="getFolderStyle(folder)"
          class="folder"
          @click="selectParentFolder(folder.value)"
        >
          {{ folder.label }}
        </div>
      </div>
    </div>
    <div class="button">
      <button @click="moveBottle">Déplacer les bouteilles</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'moveModalContent',
  components: {},
  emits: ['close'],
  props: {
    checkedBottleTickers: {
      type: Object,
    },
    folders: {
      type: Object,
    },
  },
  data() {
    return {
      selectedParentFolderId: null,
    }
  },
  computed: {
    folderTree() {
      const folderList = this.folders
      const formattedData = []

      const flattenFolderList = (folders, level = 0) => {
        if (!folders || !Array.isArray(folders)) return

        folders.forEach((folder) => {
          formattedData.push({
            label: folder.label,
            value: folder.value,
            level: level,
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderList(folder.children, level + 1)
          }
        })
      }

      flattenFolderList(folderList, 0)

      return formattedData
    },
  },
  methods: {
    ...mapActions({
      updateBottlesFolder: 'updateBottlesFolder',
    }),
    async moveBottle() {
      const payload = {
        current_folder: this.checkedBottleTickers[0].folder_uuid,
        new_parent_folder: this.selectedParentFolderId,
        bottles: [],
      }

      this.checkedBottleTickers.forEach((bottle) => {
        payload.bottles.push({
          parent_folder_uuid: this.selectedParentFolderId,
          bottle_uuid: bottle.bottle_uuid,
        })
      })

      await this.updateBottlesFolder(payload)
        .then(() => {
          this.$emit('move', this.selectedParentFolderId)
        })
        .catch(() => {})
    },
    getFolderStyle(folder) {
      let style = { 'padding-left': `${folder.level * 20 + 20}px` }

      if (folder.value === this.selectedParentFolderId) {
        style['background-color'] = '#333333'
        style['border-bottom'] = '1px solid #2666ff'
      }

      return style
    },
    selectParentFolder(folderId) {
      this.selectedParentFolderId = folderId
    },
  },
  mounted() {
    this.selectedParentFolderId = this.folders[0].value
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root';
$border-color: rgba(255, 255, 255, 0.3);

.button {
  display: flex;
  justify-content: center;

  button {
    color: white;
    background-color: #2667ff;
    padding: 14px 20px 14px 20px;
    box-shadow: none;
    border: 1px solid #444444;
    border-radius: 52px;
  }

  .disabled {
    background-color: #444444;
    color: #ffffff66;
    cursor: not-allowed;
  }
}

.folders {
  display: flex;
  flex-direction: column !important;
}

.folder-form {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.folder-menu {
  background-color: $common-modal-header-bg-color;
  border-radius: 5px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  color: white;
  margin-bottom: 1rem;
  padding: 10px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.folder {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}
</style>
