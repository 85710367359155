<template>
  <div v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0.4)">
    <div class="first-text">
      <div>
        <img
          :src="require('@/assets/svg/v2/portfolio/interrogation.svg')"
          alt="Interrogation icon"
        />
        <p>
          {{ $t('portfolio.to_guide_you') }}
          <a
            class="download"
            href="https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/TEP_TemplateUploadPortfolio-VF.xlsx"
            download="nom-du-fichier.xlsx"
          >
            {{ $t('portfolio.download') }}
          </a>
          {{ $t('portfolio.an_import_template') }}
        </p>
      </div>
    </div>
    <div class="file-zone">
      <div>
        <label
          for="input-file"
          id="drop-area"
          @dragover.prevent
          @drop="dropFile($event)"
        >
          <input
            type="file"
            id="input-file"
            name="input-file"
            @change="handleChange"
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <img
            :src="require('@/assets/svg/v2/portfolio/import.svg')"
            alt="File Icon"
          />
          <div class="input-text">
            <p>
              <span>{{ $t('portfolio.load_a_file') }}</span>
              {{ $t('portfolio.or_drag_drop') }}
            </p>
            <p class="text-grey">{{ $t('portfolio.file_csv_and_xlsx') }}</p>
          </div>
        </label>
      </div>
      <div
        v-if="file"
        class="file-preview"
        @mouseover="setHover(true)"
        @mouseout="setHover(false)"
        v-bind:class="{ isHovered: isHovered }"
        @click="deleteFile"
      >
        <div>
          <img
            :src="require('@/assets/svg/v2/portfolio/file.svg')"
            alt="File icon"
          />
          <p>{{ file && file.name !== null ? file.name : '' }}</p>
        </div>

        <img
          v-if="isHovered"
          :src="require('@/assets/svg/v2/close.svg')"
          alt="Cross icon"
          class="cross"
        />
      </div>
      <div class="button">
        <button
          @click="uploadFile"
          v-bind:class="{ disabled: disabled }"
          v-bind:disabled="disabled"
        >
          {{ $t('portfolio.import_file') }}
        </button>
      </div>
    </div>
    <div v-if="headers.length > 0" class="xlsx-infos">
      <div>
        <h4>Bouteilles conformes:</h4>
        <p>{{ allowedRows.length }}/{{ data.length }}</p>
      </div>
    </div>
    <div v-if="headers.length > 0" class="preview-zone">
      <table>
        <thead class="table-headers">
          <tr>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in data"
            :key="row[0]"
            :class="{
              'allowed-row': allowedRows.includes(row),
              'refused-row': refusedRows.includes(row),
            }"
          >
            <td v-for="cell in row" :key="cell">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="headers.length > 0" class="button">
      <button
        @click="uploadFile"
        v-bind:class="{ disabled: disabled }"
        v-bind:disabled="disabled"
      >
        {{ $t('portfolio.import_file') }}
      </button>
    </div>
  </div>
</template>

<script>
import { isNumber } from 'lodash'
import m from 'moment'
import { mapActions, mapGetters } from 'vuex'
import r from '@/services/regex.js'

export default {
  name: 'importModalContent',
  data() {
    return {
      disabled: true,
      file: null,
      isHovered: false,
      isLoading: false,

      rowsNames: [
        'EAN',
        'ISBN',
        'TICKER',
        'NAME',
        'TRADE_TYPE',
        'DATE',
        'QUANTITY',
        'NET_PRICE',
        'GROSS_PRICE',
        'FEES',
        'VAT',
        'SOURCED',
        'PRICE_CURRENCY',
        'FOLDER_ID',
        'IN_BOND',
      ],
      allowedTradesTypes: [
        'buy',
        'Buy',
        'BUY',
        'sell',
        'Sell',
        'SELL',
        'swap giver',
        'Swap giver',
        'SWAP GIVER',
        'swap taker',
        'Swap taker',
        'SWAP TAKER',
      ],

      headers: [],
      data: [],
      allowedRows: [],
      refusedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      pfmStatisticsCurrency: 'pfmStatisticsCurrency',
      pfmStatisticsPeriod: 'pfmStatisticsPeriod',
    }),
  },
  methods: {
    ...mapActions({
      getFolders: 'getFolders',
      uploadDatabase: 'uploadDatabase',
      getStatistics: 'getStatistics',
    }),
    async uploadFile() {
      this.isLoading = true

      await this.uploadDatabase(this.file)
        .then(async () => {
          const payload = {
            currency: this.pfmStatisticsCurrency,
            period: this.pfmStatisticsPeriod,
          }

          await this.getStatistics(payload)
            .then(() => {
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })

          this.$emit('close')
          this.$emit('refresh')
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    handleChange(event) {
      this.file = event.target.files[0]
      if (this.file != null) {
        this.disabled = false
        this.readFile()
      }
    },
    setHover(value) {
      this.isHovered = value
    },
    deleteFile() {
      this.file = null
      this.disabled = true
      this.data = []
      this.headers = []
    },
    dropFile(event) {
      event.preventDefault()
      const inputFile = document.getElementById('input-file')
      inputFile.files = event.dataTransfer.files
      this.file = event.dataTransfer.files[0]
      if (this.file != null) {
        this.disabled = false
      }
    },
    readFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        const XLSX = require('xlsx')

        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array', blankrows: '*' })
          const sheetName = '2-PORTFOLIO'
          const worksheet = workbook.Sheets[sheetName]

          if (worksheet) {
            const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            this.headers = excelData[0]

            excelData.forEach((row, iRow) => {
              if (row.length > 1 && iRow > 0) {
                let countAllowed = []
                let optionnalCellErrors = []
                let countEmptyBottleReference = 0
                let referenceIsChecked = false

                row.forEach((cell, iCell) => {
                  if (row.length < this.headers.length) {
                    for (
                      let index = 0;
                      index < this.headers.length - row.length;
                      index++
                    ) {
                      row.push('')
                    }
                  }

                  if (this.rowsNames[iCell] === 'EAN') {
                    if (!row[iCell]) {
                      countEmptyBottleReference++
                    }
                  }

                  if (this.rowsNames[iCell] === 'ISBN') {
                    if (!row[iCell]) {
                      countEmptyBottleReference++
                    } else if (!r.portfolio_ticker_isbn.test(cell)) {
                      // console.log('ISBN not allowed')
                    } else if (!referenceIsChecked) {
                      countAllowed++
                      referenceIsChecked = true
                    }
                  }

                  if (this.rowsNames[iCell] === 'TICKER') {
                    if (!row[iCell]) {
                      countEmptyBottleReference++
                    } else if (!r.portfolio_bottle_title.test(cell)) {
                      // console.log('Ticker not allowed')
                    } else if (!referenceIsChecked) {
                      countAllowed++
                      referenceIsChecked = true
                    }
                  }

                  if (this.rowsNames[iCell] === 'NAME') {
                    if (!row[iCell]) {
                      countEmptyBottleReference++
                    } else if (!r.portfolio_bottle_title.test(cell)) {
                      // console.log('Name not allowed')
                    } else if (!referenceIsChecked) {
                      countAllowed++
                      referenceIsChecked = true
                    }
                  }

                  if (this.rowsNames[iCell] === 'TRADE_TYPE') {
                    row[iCell] = row[iCell].toUpperCase()

                    if (
                      !this.allowedTradesTypes.includes(row[iCell]) ||
                      !row[iCell]
                    ) {
                      // console.log(`Trade type not valid`)
                    } else {
                      countAllowed++
                    }
                  }

                  if (this.rowsNames[iCell] === 'DATE' && isNumber(cell)) {
                    const milliseconds = (cell - 25569) * 24 * 60 * 60 * 1000
                    row[iCell] = m(milliseconds, 'x').format('DD/MM/YYYY')

                    if (m(row[iCell], 'DD/MM/YYYY').isValid()) {
                      countAllowed++
                    } else {
                      // console.log('Date is not valid')
                    }
                  }

                  if (this.rowsNames[iCell] === 'QUANTITY') {
                    row[iCell] = Number(parseFloat(row[iCell]).toFixed(0))

                    if (!row[iCell] || row[iCell] == 0) {
                      countAllowed++
                    } else if (!r.quantity.test(row[iCell])) {
                      // console.log('Quantity')
                    } else {
                      countAllowed++
                    }
                  }

                  if (this.rowsNames[iCell] === 'NET_PRICE') {
                    row[iCell] = Number(parseFloat(row[iCell]).toFixed(2))

                    if (!row[iCell] || row[iCell] == 0) {
                      countAllowed++
                    } else if (!r.bottle_price.test(row[iCell])) {
                      // console.log('Net price not allowed')
                    } else {
                      countAllowed++
                    }
                  }

                  if (this.rowsNames[iCell] === 'FEES') {
                    row[iCell] = Number(parseFloat(row[iCell]).toFixed(2))

                    if (!r.bottle_price.test(row[iCell])) {
                      // console.log('Fees not allowed')
                      optionnalCellErrors.push('FEES')
                    }
                  }

                  if (this.rowsNames[iCell] === 'GROSS_PRICE') {
                    row[iCell] = Number(parseFloat(row[iCell]).toFixed(2))

                    if (!r.bottle_price.test(row[iCell])) {
                      // console.log('Gross price not allowed')
                      optionnalCellErrors.push('GROSS_PRICE')
                    }
                  }

                  if (this.rowsNames[iCell] === 'VAT') {
                    row[iCell] = row[iCell]
                      ? Number(parseFloat(row[iCell]).toFixed(2))
                      : 0

                    if (!r.bottle_price.test(row[iCell])) {
                      // console.log('Vat not allowed')
                      optionnalCellErrors.push('VAT')
                    }
                  }

                  if (this.rowsNames[iCell] === 'SOURCED') {
                    if (!r.sourced.test(row[iCell])) {
                      // console.log('Sourced not allowed')
                      optionnalCellErrors.push('SOURCED')
                    }
                  }

                  if (this.rowsNames[iCell] === 'PRICE_CURRENCY') {
                    if (!r.currency.test(row[iCell])) {
                      // console.log('Currency not allowed')
                      optionnalCellErrors.push('PRICE_CURRENCY')
                    }
                  }

                  if (this.rowsNames[iCell] === 'FOLDER_ID') {
                    if (!r.folder_id.test(row[iCell])) {
                      // console.log('Folder ID not allowed')
                      optionnalCellErrors.push('FOLDER_ID')
                    }
                  }

                  if (this.rowsNames[iCell] === 'IN_BOND') {
                    if (row[iCell] !== 'Yes' && row[iCell] !== 'No') {
                      // console.log('In bond not allowed')
                      optionnalCellErrors.push('IN_BOND')
                    }
                  }
                })

                if (
                  optionnalCellErrors.length === 0 &&
                  countAllowed === 5 &&
                  countEmptyBottleReference < 4
                ) {
                  this.allowedRows.push(row)
                } else {
                  this.refusedRows.push(row)
                }

                this.data.push(row)
              }
            })

            resolve(excelData)
          } else {
            reject(
              new Error(
                `La feuille 2-PORTFOLIO n'a pas été trouvée dans le fichier.`
              )
            )
          }
        }

        reader.onerror = (error) => reject(error)
        reader.readAsArrayBuffer(this.file)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root';

.download {
  color: white;
  text-decoration: none;
}

.download:active {
  color: white;
}

.cross {
  width: 17px !important;
}

.isHovered {
  background-color: #444444;
}

.file-preview {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
  }

  img {
    width: 14px;
    margin-right: 10px;
  }
}

.file-zone {
  margin-top: 60px;
}

.first-text {
  display: flex;
  flex-direction: row;
  background-color: #444444;
  padding: 1rem 1rem;
  color: #ffffff99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2392156863);
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  span {
    color: #ffffff;
  }
}

h4 {
  color: #ffffff;
  size: 16px;
}

#drop-area {
  display: flex;
  flex-direction: column;
  border: 2px dashed #ffffff3d;
  height: 160px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 15px;
  cursor: pointer;

  input {
    display: none;
  }

  img {
    height: 40px;
    margin-bottom: 15px;
  }

  .input-text {
    text-align: center;
    p {
      color: #ffffff;
    }

    span {
      color: #2667ff !important;
    }

    .text-grey {
      color: #ffffff99;
    }
  }
}

.button {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    color: white;
    background-color: #2667ff;
    padding: 14px 20px 14px 20px;
    box-shadow: none;
    border: 1px solid #444444;
    border-radius: 52px;
  }

  .disabled {
    background-color: #444444;
    color: #ffffff66;
    cursor: not-allowed;
  }
}

.xlsx-infos {
  color: white;
  margin: 10px 0;

  div {
    display: flex;

    p {
      margin: 0 8px;
    }
  }
}

.preview-zone {
  background: $common-modal-bg-color;
  margin-top: 15px;
  color: white;
  overflow-x: auto;

  table {
    margin: auto;
    border-collapse: collapse;
    border-spacing: 0;

    .table-headers {
      padding-bottom: 20px;

      tr {
        border-bottom: 2px solid white;

        th {
          padding: 0 5px;
        }
      }
    }

    tbody {
      font-size: 12px;

      tr {
        border-bottom: 1px solid black;

        td {
          text-align: center;
          padding: 4px 0px;
          white-space: nowrap;
        }
      }
    }
  }
}

.allowed-row {
  background: rgba(0, 150, 0, 0.325);
}

.refused-row {
  background: rgba(255, 0, 0, 0.28);
}
</style>
